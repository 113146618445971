<script setup lang="ts">
import { storeToRefs } from 'pinia'
import {
  topBanner,
  trustedLogos,
  steps,
  featuredList,
  topCreatorsGigsCarousel,
  partners,
  caseStudies,
  fullService,
  categories,
  popularContent,
} from 'cms/data/homepage.json'

const authStore = useAuthStore()
const { isLoggedIn, isLoginProcessing } = storeToRefs(authStore)
const { isCreator } = storeToRefs(useCustomerStore())
const { isPublished } = useCreatorStore()

useHead({
  title: 'Creator Content for your Business',
  meta: [
    {
      name: 'description',
      content:
        'Find the best creator content to advertise your business. Visit BrandedContent.com today.',
    },
  ],
})

if (isCreator.value && isPublished) {
  navigateTo('/account/dashboard')
}
</script>

<template>
  <div>
    <div v-if="!isLoggedIn || isLoginProcessing || isCreator">
      <CmsTopBanner :image="topBanner.image">
        <CmsSearchBannerContent v-bind="topBanner.content" />
      </CmsTopBanner>

      <section class="py-8 md:py-4">
        <CmsLogoListImage v-bind="trustedLogos" />
      </section>

      <section class="py-8 shadow-dark">
        <div class="container">
          <CmsSteps
            v-bind="steps"
            class="mb-4 md:mb-8"
          />
          <CmsFeaturedList v-bind="featuredList" />
        </div>
      </section>

      <section class="bg-gray-600 py-8">
        <div class="container">
          <GigTopCreatorsGigsCarousel v-bind="topCreatorsGigsCarousel" />
        </div>
      </section>

      <section class="container pb-[72px] pt-2 md:pb-14 md:pt-8">
        <CmsCarouselBanners v-bind="partners" />
      </section>

      <section class="bg-gray-600 py-8">
        <div class="container">
          <CmsCaseStudies v-bind="caseStudies" />
        </div>
      </section>

      <section class="container pb-12 pt-8 md:py-16">
        <CmsBannerFullService v-bind="fullService" />
      </section>
    </div>
    <div
      v-else
      class="pt-4"
    >
      <section class="container py-8 md:flex md:gap-4">
        <AccountBrandAccountStatus
          v-if="!isCreator"
          class="mb-4 md:w-1/2 lg:w-[35%]"
        />

        <GigCategoryCarouselSecondary
          :items="categories"
          title="Explore the Marketplace"
          see-all-label="See All"
          :is-full-width="isCreator"
          :class="!isCreator ? 'w-full md:w-1/2 lg:w-[65%]' : 'w-full'"
        />
      </section>

      <section class="pb-16 pt-8">
        <div class="container min-h-[270px]">
          <CmsCarouselCards v-bind="popularContent" />
        </div>
      </section>

      <section class="container">
        <GigPopularGigsCarousel />
      </section>
    </div>
  </div>
</template>
