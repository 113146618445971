<script setup lang="ts">
import type { CategoryLinkItem } from 'gig/types/category-link-item'
import AppSlider from '@/components/AppSlider.vue'

const props = withDefaults(
  defineProps<{
    items: CategoryLinkItem[]
    title: string
    seeAllLabel: string
    isFullWidth: boolean
  }>(),
  {
    isFullWidth: false,
  },
)

const visiblesSlidesMq768 = computed(() => (props.isFullWidth ? '3.75' : '3'))
const slidesNumberMq1024 = computed(() => (props.isFullWidth ? '4' : '3'))
const visiblesSlidesMq1024 = computed(() => (props.isFullWidth ? '4.75' : '3'))
</script>

<template>
  <div class="py-4 md:py-0">
    <div
      class="mb-5 flex items-center justify-between pb-2 md:pb-1.5 xl:pr-1.5"
    >
      <h2 class="w-1/2 pr-8 text-2xl md:w-auto md:p-0">{{ title }}</h2>
      <NuxtLink
        to="/search/all"
        class="p-button p-button-outlined p-button-small"
        :aria-label="`See all: ${title}`"
      >
        <span class="p-button-label nowrap">{{ seeAllLabel }}</span>
        <i class="pi pi-angle-right p-button-icon p-button-icon-right" />
      </NuxtLink>
    </div>
    <AppSlider
      prev-button-class="hidden md:hidden"
      next-button-class="hidden md:hidden"
      class="h-[227px]"
    >
      <div
        v-for="(item, key) in items"
        :key="key"
        class="slides-item block snap-start"
      >
        <NuxtLink
          :to="item.url"
          class="group flex h-[227px] w-[227px] flex-col items-center justify-center rounded-md border border-solid border-gray-400 px-8 py-6"
        >
          <Component
            :is="useIconComponent(item.icon)"
            class="max-h-2rem mb-3 text-gray-100"
            height="32"
            aria-hidden="true"
          />

          <p
            class="m-0 text-center text-sm font-medium text-gray-100 group-focus:text-blue-100"
          >
            {{ item.name }}
          </p>
        </NuxtLink>
      </div>
    </AppSlider>
  </div>
</template>

<style scoped>
:deep(.slides) {
  --gap: 16px;
  gap: var(--gap);
}

.slides-item {
  --slides-number: 3;
  --visible-slides: 2.15;
  --spacing-per-slide: calc(var(--gap) * (var(--slides-number) - 1)) /
    var(--slides-number);
  flex-basis: calc((100% / var(--visible-slides)) - var(--spacing-per-slide));
  flex-grow: 0;
  flex-shrink: 0;

  @media (min-width: 400px) {
    --slides-number: 2;
    --visible-slides: 1.75;
  }

  @media (min-width: 550px) {
    --slides-number: 3;
    --visible-slides: 2.75;
  }

  @media (min-width: 768px) {
    --slides-number: 3;
    --visible-slides: v-bind(visiblesSlidesMq768);
  }

  @media (min-width: 1024px) {
    --slides-number: v-bind(slidesNumberMq1024);
    --visible-slides: v-bind(visiblesSlidesMq1024);
  }
}

a:focus :deep(path) {
  @apply !stroke-blue-200;
}
</style>
