<script setup lang="ts">
import type { Image } from '@/types/image'

export interface Banner {
  image?: Image
  url?: string
  heading?: string
}
const props = defineProps<Banner>()

const wrapperTag = computed(() =>
  props.url ? resolveComponent('NuxtLink') : 'div',
)
</script>

<template>
  <Component
    :is="wrapperTag"
    :to="url"
    class="relative flex aspect-[190/300] h-full max-w-full rounded-md after:absolute after:z-10 after:h-[inherit] after:w-full after:rounded-md after:bg-gradient-to-b after:from-black/70 after:to-black/0 after:content-['']"
  >
    <h3
      v-if="heading"
      class="absolute z-20 p-2 text-xl font-medium text-white"
    >
      {{ heading }}
    </h3>
    <NuxtPicture
      :img-attrs="{
        class: 'h-full w-full object-cover rounded-md',
      }"
      :src="image?.url"
      :alt="image?.alt"
      :width="image?.dimensions.w"
      :height="image?.dimensions.h"
      sizes="sm:300px lg:211px"
      loading="lazy"
    />
  </Component>
</template>
