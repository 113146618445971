<script setup lang="ts">
import { storeToRefs } from 'pinia'
import IconCheckCircle from '@/assets/images/check-circle.svg'

const customerStore = useCustomerStore()
const { customer, customerReviewsTotal, customerOrdersTotal } =
  storeToRefs(customerStore)
const ordersChecked = computed(() => !!customerOrdersTotal.value)
const reviewsChecked = computed(() => !!customerReviewsTotal.value)

const completeABrandAccount = computed(() => [
  {
    id: 'info',
    title: 'Complete your personal information',
    text: 'Fill out your personal details to start buying',
    url: '/account/settings',
    checked: true,
  },
  {
    id: 'orders',
    title: 'Create your first order',
    text: 'Create orders around topics, a shared goals',
    url: '/search/all',
    checked: ordersChecked.value,
  },
  {
    id: 'reviews',
    title: 'Make your first review',
    text: 'Rate the creators you worked with',
    url: '/account/brand/orders',
    checked: reviewsChecked.value,
  },
])

const completionProgress = computed(() => {
  let done = 0
  for (const item of completeABrandAccount.value) {
    if (item.checked) {
      done++
    }
  }
  return Math.floor((done * 100) / 3)
})

if (!customerReviewsTotal.value && customer.value?.id) {
  await customerStore.checkReviews({
    limit: 1,
    where: encodeURIComponent(`customer(id="${customer.value.id}")`),
  })
}
if (customerOrdersTotal.value === null) {
  await customerStore.checkOrders({
    limit: 1,
    expand: 'lineItems[*].variant.attributes[*].value',
  })
}
</script>

<template>
  <div class="flex flex-col md:mb-0">
    <h2 class="mb-5 flex py-2 text-2xl md:pt-1">
      Complete your account

      <span class="ml-4 text-blue-200"> {{ completionProgress }}&percnt; </span>
    </h2>

    <ul
      class="my-1 flex min-h-[227px] flex-1 flex-col justify-evenly rounded-md shadow-around md:px-2"
    >
      <li
        v-for="(item, i) in completeABrandAccount"
        :key="i"
      >
        <NuxtLink
          :href="item.checked ? undefined : item.url"
          :aria-disabled="item.checked ? 'true' : null"
          :role="item.checked ? 'link' : null"
          :class="[
            'group flex items-center rounded-md p-3 text-gray-200',
            {
              'text-gray-300 transition-colors hover:bg-blue-100 hover:text-white':
                !item.checked,
            },
          ]"
        >
          <span
            v-if="!item.checked"
            class="block h-5 w-5 self-start rounded-full border-2 border-gray-400 bg-gray-600 group-hover:border-white group-hover:bg-blue-200"
          />
          <span
            v-else
            class="relative block h-5 w-5 self-start rounded-full bg-blue-500"
          >
            <IconCheckCircle
              class="absolute left-0 top-0 h-full w-full scale-125 fill-blue-200 stroke-blue-200"
            />
          </span>

          <div class="ml-3 flex-1">
            <h3 class="flex">
              <strong class="font-medium">{{ item.title }}</strong>
            </h3>

            <p class="mt-1.5">{{ item.text }}</p>
          </div>

          <i
            :class="[
              'pi pi-angle-right ml-auto text-[22px]',
              { 'text-gray-400': item.checked },
            ]"
          />
        </NuxtLink>
      </li>
    </ul>
  </div>
</template>
