<script setup lang="ts">
const props = withDefaults(
  defineProps<{
    items: string[]
    typingSpeed?: number
    changeTypedItemDelay?: number
  }>(),
  {
    typingSpeed: 100,
    changeTypedItemDelay: 2000,
  },
)

const displayedText = ref('')
const itemIndex = ref(0)
const characterIndex = ref(0)

const typedItem = computed(() => props.items[itemIndex.value])

const changeTypedItem = () => {
  if (itemIndex.value === props.items.length - 1) {
    itemIndex.value = 0
  } else {
    itemIndex.value += 1
  }
}
const typeText = () => {
  if (characterIndex.value < typedItem.value.length) {
    displayedText.value += typedItem.value.charAt(characterIndex.value)
    characterIndex.value += 1
    setTimeout(typeText, props.typingSpeed)
  } else {
    setTimeout(eraseText, props.changeTypedItemDelay)
  }
}
const eraseText = () => {
  if (characterIndex.value > 0) {
    displayedText.value = typedItem.value.substring(0, characterIndex.value - 1)
    characterIndex.value -= 1
    setTimeout(eraseText, props.typingSpeed)
  } else {
    changeTypedItem()
    setTimeout(typeText, props.changeTypedItemDelay)
  }
}

onMounted(typeText)
</script>

<template>
  <span :aria-label="items.join(', ')">
    <span>{{ displayedText }}</span>
    <span class="animate-blink">|</span>
  </span>
</template>
